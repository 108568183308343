body {
    margin: 0;
}

section.main {
    padding: 1em;
}

.phone-view {
    display: flex
}
.phone-view .phone-detail {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
}

.phone-view .phone-detail .model,
.phone-view .phone-price {
    font-weight: bold;
    font-size: larger;
}

.phone-commands {
    display: flex;
    flex-direction: column;
    padding-left: 1em;
}

.phone-commands > button {
    margin-bottom: 0.25em;
}

.list-commands {
    padding: 0.2em 0
}

.list-group .item-commands {
    padding-right: 1em;
}

.item-commands {
    display: flex;
    flex-direction: column;
}

.item-commands > button {
    margin-bottom: 0.2em;
}

.phonesmart-main {
    display: grid;
    grid-template-columns: 1fr 2fr;
    column-gap: 1em;
}

.phonesmart-main .plans {
    border: 1px solid green;
}

.plan-price {
    text-align: right;
}

.plan-detail {
    display: flex;
    flex-direction: column;
}

.plan-detail .main {
    font-weight: bold;
    padding-bottom: 1em;
}

.header-slogan {
    font-style: italic;
}